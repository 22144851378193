import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import PromotionBanner from '../components/PromotionBanner/PromotionBanner'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import img from '../images/industry-sector/team_industry.svg'
import IndustryTemplateLayout from '../components/IndustrySector/IndustryTemplateLayout'
import MainGrid from '../components/styling/MainGrid'
import Breadcrumb from '../components/shared/Breadcrumb/Breadcrumb'
import IndustryLeftCol from '../components/IndustrySector/IndustryLeftCol'
import IndustryRightCol from '../components/IndustrySector/IndustryRightCol'
import IndustryDescription from '../components/IndustrySector/IndustryDescription'
import logo from '../images/homepage/popin-anim/engineering.svg'
import CaseStudyContent from '../components/CaseStudy/CaseStudyContent'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About us',
    url: '/about-us',
  },
  {
    title: 'Case Studies',
    url: '/case-studies',
  },
  {
    title: 'Skyline Green Interiors',
    url: '/skyline-green-interiors',
  },
]

const Skyline = () => {
  return (
    <Layout
      activeLink="/case-studies"
      title="R&D Skyline Green Interiors"
      description="R&D Skyline Green Interiors"
    >
      <MainGrid noPaddingMobile noPaddingTablet>
        <Breadcrumb data={breadcrumbData} />
      </MainGrid>
      <IndustryTemplateLayout>
        <IndustryLeftCol
          category="Case Studies"
          title="Skyline Green Interiors"
          backLink="/case-studies"
        />
        <IndustryRightCol>
          <IndustryDescription title="Skyline Green Interiors" />
          <CaseStudyContent
            heading="Engineering, environmental & building control consultancy"
            paragraph='"We hadn’t made any R&D claims before, and it was our accountant who recommended we speak to RDA. I spoke with Gary Waskett and he made us feel totally at ease – he’s a very personable guy who guided us through things. '
            // imgDesktop={img}
            // imgMobile={img}
            alt="Aeroplane"
            logo={logo}
            content={[
              <div>
                <p>
                  As we went through the process it was extremely seamless, all
                  the hard work was taken care of by RDA. We didn’t realise we
                  would qualify for R&D until Gary went through everything that
                  counted as qualifying expenditure with us.
                </p>
                <p>
                  This helped us go away and put examples together where R&D had
                  been used. Now we record the R&D as it happens in preparation
                  for the end-of-year reporting.
                </p>
                <p>
                  We would not be looking elsewhere for people to represent us
                  on our R&D claims – we’d recommend RDA to anyone. In
                  fact, we already have!"
                </p>

                <h6>Michael Moore – Head of Finance</h6>
              </div>,
            ]}
          />
        </IndustryRightCol>
      </IndustryTemplateLayout>
      <PromotionBanner />
      <GeneralEnquiryForm />
      <CallBackBanner />
    </Layout>
  )
}

export default Skyline
